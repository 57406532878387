import connectedIcon from "assets/svg/projectorConnected.svg"


export const LoadingState = ({connected, reconnecting, connecting}: {
    connected: boolean,
    reconnecting: boolean,
    connecting: boolean
}) => {
    return <div className="fixed bottom-12 right-12">
        <span className="rotating-icon">
        <img src={connectedIcon} alt={"Icon for connection"}/>
        </span>
    </div>
}