import {createSlice, PayloadAction} from "@reduxjs/toolkit";


interface InitialStateInterface {
    onGoingPresentation: boolean,
    onGoingEvent: boolean,
    eventId: string,
    presentationId: string,
    presentationIsEvent: boolean,
    teamId: string
}

interface PresentationPayload {
    active: boolean;
    id: string;
    isEvent: boolean,
    teamId?: string,
    eventId?: string;

}

const initialState: InitialStateInterface = {
    onGoingPresentation: false,
    onGoingEvent: false,
    eventId: "",
    presentationId: "",
    presentationIsEvent: false,
    teamId: ""

}


export const onGoingAction = createSlice({
    name: "onGoingSlice",
    initialState,
    reducers: {
        setOnGoingPresentation(state, action: PayloadAction<PresentationPayload>) {
            state.onGoingPresentation = action.payload.active;
            if (action.payload.isEvent) {
                state.presentationIsEvent = true
                state.eventId = action.payload.eventId ?? ""
            } else {
                state.presentationIsEvent = false
            }

            //

            if (action.payload.active) {
                state.presentationId = action.payload.id;
            } else {
                state.presentationId = ""
            }
        },
        setOnGoingEvent(state, action: PayloadAction<PresentationPayload>) {
            state.onGoingEvent = action.payload.active;
            if (action.payload.active) {
                state.eventId = action.payload.id;
                state.teamId = action.payload.teamId ?? ""
            } else {
                state.eventId = "";
            }
        }
    }
})

export default onGoingAction.reducer

export const {setOnGoingPresentation, setOnGoingEvent} = onGoingAction.actions