import ErrorPage from 'views/ErrorPage';
import {ProjectorLanding} from "../../../views/projector";
import {ProjectorViewMode} from "../../../views/projector/projector-view-mode";


const projectorRoutes = [
    {
        path: '/projector',
        errorElement: <ErrorPage/>,
        children: [
            {
                path: ':presentationId',
                element: <ProjectorLanding/>,
            },
            {
                path: 'projector-view/:friendlyId',
                element: <ProjectorViewMode/>
            }

        ],
    },
];

export default projectorRoutes;
