import projectorIcon from "assets/svg/projectorIcon.svg"
import teamDocsIcon from "assets/svg/projectorTeamDocsIcon.svg"
import {LoadingState} from "../../components/UI/ProjectorLoader/loading-state";
import {useNavigate, useParams} from "react-router-dom";
import {usePresentationConnectSignalR} from "../../hooks/SignalR/usePresentationConnectSignalR";
import {useEffect, useRef, useState} from "react";
import Cookies from "js-cookie"
import {CButton} from "../../components/UI/Button/Buttons";
import {CopyToClipBoard} from "../../components/UI/wrapper/CopyToClipBoard";

export const ProjectorLanding = () => {
    const {presentationId} = useParams()
    const projectorConnectionId = Cookies.get("projectorConnectionId");
    const projectorPresentationId = Cookies.get("projectorPresentationId");
    const {connection, connected, reconnecting, error, connecting} = usePresentationConnectSignalR();
    const [projectorConnectionIDState, setProjectorConnectionIDState] = useState("");
    const projectorConnectorIDref = useRef("")
    const [attendPayload, setAttendPayload] = useState<{ message: string }>();
    const [projectorOtp, setProjectorOtp] = useState(Array(6).fill("-"));
    const navigate = useNavigate();

    // i should only set the cookie after it has being authorized by the presenter, not before


    const handleListenToEvent = (id: string, eventName: string, status: any, message: any, presentaConnectionId: string) => {
        if (eventName === "Attend") {
            if (status) {
                if (!projectorConnectionId || projectorConnectionId !== presentationId) {
                    // Cookies.set("projectorConnectionId", presentaConnectionId, {expires: 1})
                    projectorConnectorIDref.current = presentaConnectionId;
                    setProjectorConnectionIDState(presentaConnectionId)

                }
                if (message.message) {
                    setProjectorOtp(message.message.split(""))
                }
            }
        }

    }

    const handleProjectorAuthorized = (id: string) => {
        console.log(id, projectorConnectorIDref.current, "Project Authorized here")
        console.log(projectorConnectorIDref.current, "Value here")
        if (projectorConnectorIDref.current) {
            Cookies.set("projectorConnectionId", projectorConnectorIDref.current, {expires: 1})
            Cookies.set("projectorPresentationId", presentationId ?? "", {expires: 1})
            navigate(`/projector/projector-view/${presentationId}`);

        }
    }

    useEffect(() => {
        if ((projectorPresentationId === presentationId) && projectorConnectionId) {
            // redirect to the navigation page
            navigate(`/projector/projector-view/${presentationId}`);
        } else {
            if (connected && connection && connection.state === "Connected") {
                connection.on("EventResponse", handleListenToEvent)
                connection.on("ProjectorAuthorized", handleProjectorAuthorized)
                try {
                    connection.invoke("Attend", presentationId, 2, projectorConnectionId, false, null)
                } catch (e) {

                }
            }
        }
    }, [connected]);


    return <div
        className="h-screen p-10 min-h-screen max-h-screen bg-[#F2F2F2] flex justify-center items-center">
        <LoadingState connected={false} reconnecting={false} connecting={false}/>
        <div className=" w-full h-full border border-[#EBF1FF] flex rounded-2xl overflow-hidden">
            <div className="bg-[#F9F7F4] w-[40%] h-full p-8 py-12">
                <div>
                    <p className={"font-grotesk font-semibold text-4xl"}>Teamdocs Projector</p>
                    <p className="text-[#333333] text-base font-grotesk my-2 w-[60%]">
                        Contact the presenta for the meeting code or it’s displayed on the screen
                    </p>
                </div>
                <div className="w-full items-center justify-center h-full flex flex-grow h-full">
                    <img className="h-[80%]" src={projectorIcon} alt={""}/>
                </div>
            </div>
            <div className="bg-white w-[60%]  flex h-full items-center flex-col justify-center px-10">
                <div>
                    <img src={teamDocsIcon} alt={""}/>
                </div>
                <p className="font-grotesk mt-3 font-semibold text-xl">
                    Enter the meeting code below to project your presentation
                </p>
                <div className="flex justify-center items-center gap-2 mt-4">
                    {projectorOtp.map(item => {
                        return <span className="px-6 text-4xl  font-bold py-4 rounded-lg bg-[#F6F3EF]">
                        {item}
                    </span>
                    })}
                </div>
                <div className="mt-3">
                    <CopyToClipBoard text={projectorOtp.join("")}>
                        <CButton text={"Copy Code"}/>
                    </CopyToClipBoard>
                </div>
            </div>
        </div>
    </div>
}

