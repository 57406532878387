import React, {useEffect, useRef, useState} from "react";
import instance from "../../lib/axiosInterceptor";
import {getConnectionId} from "../../utils/localStorageServices";
import {
    AgendaInterface,
    EventsInterface,
    InnerPresentationInterface,
    PresentationDisplayInterface,
    PresentationDocInterface,
    SlideInterface,
    UnlockedInterface
} from "../../interfaces/interfaces-data";
import {useParams} from "react-router-dom";
import {documentHasSlides} from "../../utils/documentHasSlides";
import {imageStream} from "../../utils/imageStream";
import {toast} from "sonner";
import {UserPresentationWrapper} from "../../components/UI/wrapper/UserPresentationWrapper";
import {DesktopViewerPresentationScreen} from "../../components/UI/Presentation/viewer/DesktopViewerPresentationScreen";
import advertiseImagePlaceholder from "assets/svg/advertiseImagePlaceholder.svg";
import {CopyToClipBoard} from "../../components/UI/wrapper/CopyToClipBoard";
import {usePresentationConnectSignalR} from "../../hooks/SignalR/usePresentationConnectSignalR";
import Cookies from "js-cookie";
import arrowRight from "assets/svg/arrow-right-circle.svg"
import arrowLeft from "assets/svg/arrow-left-circle.svg";
import {QRCode} from "antd";

export const ProjectorViewMode = () => {
    const projectorConnectionId = Cookies.get("projectorConnectionId");
    const [presentationDetails, setPresentationDetails] = useState<InnerPresentationInterface>()
    const [presentationId, setPresentationId] = useState("")
    const [rearrangedDoc, setRearrangedDoc] = useState<PresentationDocInterface[]>([])
    const unlockedSlidesRef = useRef<UnlockedInterface>({});
    const [activeDocumentId, setActiveDocumentId] = useState("");
    const [presenterActiveDocument, setPresenterActiveDocument] = useState("")
    const [presentationDetailsLoaded, setPresentationDetailsLoaded] = useState(false);
    const [firstChunkLoading, setFirstChunkLoading] = useState(true);
    const [presentationDisplayDetails, setPresentationDisplayDetails] = useState<PresentationDisplayInterface>()
    const presentationDisplayDetailsRef = useRef<PresentationDisplayInterface>({});
    const [upcomingEvent, setUpcomingEvent] = useState<AgendaInterface>();
    const [eventDetails, setEventDetails] = useState<EventsInterface>();
    const [eventPresentationEnded, setEventPresentationEnded] = useState(false);


    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [presenterActiveSlideIndex, setPresenterActiveSlideIndex] = useState(0);
    const [overallDocLoadedDetails, setOverallDocLoadedDetails] = useState({
        loaded: false,
        noDownloaded: 0,
        justLoadedDocId: ""
    })
    const [userOutOfSyncState, setUserOutOfSyncState] = useState(false);
    const [idleState, setIdleState] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const {connection, connected, reconnecting, connecting, error} = usePresentationConnectSignalR()

    const handleNext = () => {
        if (presentationDisplayDetails && (presentationDisplayDetails?.[activeDocumentId].length - 1 === activeSlideIndex)) {
            return
        }
        connection?.invoke("MoveSlide", presentationId, activeSlideIndex + 1, activeDocumentId, null);
        setActiveSlideIndex(activeSlideIndex + 1)

    }
    const handlePrevious = () => {
        if (activeSlideIndex === 0) {
            return
        }
        connection?.invoke("MoveSlide", presentationId, activeSlideIndex - 1, activeDocumentId, null);
        setActiveSlideIndex(activeSlideIndex - 1)
    }


    const {friendlyId} = useParams();


    const getDocumentDetails = async (docId: string) => {
        try {
            const result = await instance.get(`/Document/${docId}`)
            if (result.data.slides.length && documentHasSlides((result.data.url))) {
                const reArrangedData = result.data.slides.sort(
                    (a: SlideInterface, b: SlideInterface) => {
                        if (a.position > b.position) {
                            return 1;
                        } else if (a.position < b.position) {
                            return -1;
                        }
                        return 0;
                    }
                );

                // Get the current slide in the document that the presenter is on
                // Find the index and then load five from it and then load the remaining after you have availed the user with the 10 slides
                // const activeSlide = presentationDetails.p

                // get the length of the slide , the and then get the current slide , and see if you can load five from the current Slide


                //save the expected data format for the data here inside the data that is attached to the useRef, that way it can be easily updated once any change is noticed or whatnot
                presentationDisplayDetailsRef.current[docId] = reArrangedData


                const dataLength = presentationDisplayDetailsRef.current[docId].length;
                // see if the remaining slide is up to 5 from the current index, then update the slides with that information
                const currentSlideIndex = presentationDetails?.presentation.currentSlide ?? 0

                const remainingSlides = (dataLength - 1) - currentSlideIndex
                // Basically fetching the current document and five forward
                console.log(remainingSlides, "Remaing Slides value hereee")
                if (remainingSlides === 0) {
                    // then load just the current document and then start loading the remaining documents

                    const imageUrlVal = await imageStream(presentationDisplayDetailsRef.current[docId][currentSlideIndex].id);
                    presentationDisplayDetailsRef.current[docId][currentSlideIndex].imgUrl = imageUrlVal ?? ""
                    // The only reason , i am doing this is because the backend does not send a correct data
                    if (docId === activeDocumentId) {
                        presentationDisplayDetailsRef.current[docId][currentSlideIndex].unlocked = true
                    }
                    setPresentationDisplayDetails(presentationDisplayDetailsRef.current)
                    if (firstChunkLoading) {
                        setFirstChunkLoading(false)
                        setActiveDocumentId(docId);
                        setPresenterActiveDocument(docId)
                        setActiveSlideIndex(currentSlideIndex)
                        setPresenterActiveSlideIndex(currentSlideIndex);
                    }

                } else if (remainingSlides > 0) {
                    // load a maximum of five documents and then show the user to work with that basically
                    let indexActive = 0
                    for (indexActive; indexActive < remainingSlides; indexActive++) {
                        // when the index is zero , i want to update that the file is unlocked
                        if (indexActive === 0 && activeDocumentId === docId) {
                            presentationDisplayDetailsRef.current[docId][currentSlideIndex].unlocked = true
                        }
                        const imageUrlVal = await imageStream(presentationDisplayDetailsRef.current[docId][currentSlideIndex + indexActive].id);
                        presentationDisplayDetailsRef.current[docId][currentSlideIndex + indexActive].imgUrl = imageUrlVal ?? ""
                        // break after the indexActive is 5
                        if (indexActive === 5) {
                            break
                        }
                    }
                    // Now that the expected Active chunk of the data is loaded , then i can easily update the ui and show the viewer something to view basically , basically always update the ref value
                    setPresentationDisplayDetails(presentationDisplayDetailsRef.current)
                    if (firstChunkLoading) {
                        setFirstChunkLoading(false)
                        setActiveDocumentId(docId);
                        setPresenterActiveDocument(docId)
                        setActiveSlideIndex(currentSlideIndex)
                        setPresenterActiveSlideIndex(currentSlideIndex);
                    }

                }
                // Now load the remaining documents
                //Note that while this ref is active, the good things is that it is used to control every update for presentationDisplayDetails and also to ensure , so in cases where there the user moves the slides and there is an update on the presentataionDisplayDetailsState through it's corresponding ref, everything that has not been added to it before would automatically be added to it.

                let index = 0
                for (const slide of presentationDisplayDetailsRef.current[docId]) {
                    if (presentationDisplayDetailsRef.current[docId][index].imgUrl) {
                        index++
                        continue
                        // that is once there is some sort of data in the url, then no api call should be made
                    }
                    const result = await imageStream(slide.id);
                    presentationDisplayDetailsRef.current[docId][index].imgUrl = result ?? ""
                    index++
                }
                // Activate unlocked slides after all the documents have been loaded , this is because, we don't want a situation where it is shown to the user that they can go backwards but then in the actual sense the image is not exactly loaded yet for them
                // Curate data from the presentation Display Details
                if (presentationDetails && unlockedSlidesRef.current?.[docId]) {
                    unlockedSlidesRef.current[docId].forEach(item => {
                        if (presentationDisplayDetailsRef.current) {
                            presentationDisplayDetailsRef.current[docId][item].unlocked = true
                        }
                    })
                }
                setPresentationDisplayDetails({...presentationDisplayDetailsRef.current});
            } else {
                // this basically implies that the document only has one slide
                const slideObject: SlideInterface[] = [
                    {
                        id: result.data.id,
                        url: result.data.url,
                        position: 1,
                        title: result.data.title,
                        note: result.data.note ?? "",
                        imgUrl: "",
                        unlocked: activeDocumentId === docId
                    }
                ]

                try {
                    slideObject[0]["imgUrl"] = (await imageStream(result.data.id)) ?? "";
                    presentationDisplayDetailsRef.current[docId] = slideObject;
                    if (firstChunkLoading) {
                        setFirstChunkLoading(false)
                        setActiveDocumentId(docId);
                        setPresenterActiveDocument(docId)
                        setActiveSlideIndex(0)
                        setPresenterActiveSlideIndex(0);
                    }
                    if (presentationDetails && unlockedSlidesRef.current?.[docId]) {
                        unlockedSlidesRef.current[docId].forEach(item => {
                            if (presentationDisplayDetailsRef.current) {
                                presentationDisplayDetailsRef.current[docId][item].unlocked = true
                            }
                        })
                    }
                    setPresentationDisplayDetails({...presentationDisplayDetailsRef.current});
                } catch (e) {
                    toast.error("FE: Error Loading Document")
                }

            }

            // Check if all the documents have been loaded
            if (Object.keys(presentationDisplayDetailsRef.current).length === presentationDetails?.doc.length) {
                setOverallDocLoadedDetails({
                    loaded: true,
                    noDownloaded: Object.keys(presentationDisplayDetailsRef.current).length,
                    justLoadedDocId: docId
                });
            } else {
                setOverallDocLoadedDetails({
                    loaded: false,
                    noDownloaded: Object.keys(presentationDisplayDetailsRef.current).length,
                    justLoadedDocId: docId
                });
            }

        } catch (e) {

        }

    }

    const handleSlideMove = (id: string, int: number, docId: string) => {
        console.log("I got triggered here", docId);
        console.log(presentationDisplayDetails)
        // set the active index and indicate that the slide is unlocked too,
        // if the documentId does not exist or if the document id exist but it does not have an image url , then there's a need to show a loading state instead of just showing the image property that does not exit

        // Now , what i really have to do at this point is to pretty much check if the doc id does not exist and when it doesn't exist and i pretty much want to try to have the document active in presentationDetailsRef and then also ensure that it is unlocked
        if (!presentationDisplayDetailsRef.current[docId]) {
            // Then the unlocked Slides has to be updated with the document id and the index of the files unlocked
            if (!unlockedSlidesRef.current[docId]) {
                // start from scratch and then push the current index into the ref
                unlockedSlidesRef.current[docId] = [int];
            } else {
                unlockedSlidesRef.current[docId].push(int)
            }
        }
        // if (!userOutOfSyncRef.current) {
        //     // This is for when the user is out of sync with the presenter , the activeIndex is not automatically controlled , it only controls the viewer's screen when the user is not out of sync , but then what we do is still to keep track of the presenter's movement
        //     setActiveSlideIndex(int)
        // }
        setActiveSlideIndex(int)
        setPresenterActiveSlideIndex(int);
        setPresenterActiveDocument(docId)
        setActiveDocumentId(docId)
        // if (!userOutOfSyncRef.current) {
        //     // We use the ref becuase of the closure thingy
        // }
        // Now Unlock the particular Slide
        // find the position it is on the presentationDisplayDetails
        // Now check if it is unlocked or not and set the unlocked property , this is for the current File
        if (presentationDisplayDetailsRef.current && !presentationDisplayDetailsRef.current[docId][int].unlocked) {
            presentationDisplayDetailsRef.current[docId][int].unlocked = true;
            setPresentationDisplayDetails(presentationDisplayDetailsRef.current);
        }

        // Do for the previous file that was navigated from
        // if (presentationDisplayDetailsRef.current && !presentationDisplayDetailsRef.current[docId][int].unlocked) {
        //     presentationDisplayDetailsRef.current[docId][int].unlocked = true
        // }
    }


    const getPresentationDetails = async (eventPresentationId ?: string) => {
        try {
            const result = await instance.get(`/presentation/${eventPresentationId ? eventPresentationId : friendlyId}?presentaConnectionId=${getConnectionId()}`);
            if (result.data) {
                setPresentationId(result.data.presentation.id);
                setPresentationDetails(result.data);
                unlockedSlidesRef.current = {...result.data.presentation.unlocked}
                // Get the activeDocument and then prioritize it for loading, after getting it , then prioritize the activeSlide in the document
                const activeDocFromPresenter = result.data.presentation.currentItem ?? 0;
                // it really doesn't matter how i really order this file , what basically matters is that the user has access to the file when they presenter moves, so i can basically rearrange this and then do the needful,

                // New Step : Get the inex of the active document , if it is not zero , then rearrange it to be the first document

                let presentationDocs = [...result.data.doc];
                // if the number of slides is 1 then the presenter is also going to have just one doucment active
                if (presentationDocs.length) {
                    const findActiveDocIndex = presentationDocs.findIndex(item => item.documentId === activeDocFromPresenter);
                    // i don't need to check if the index exists because , every currentItem is gotten from the doc (docId);
                    if (findActiveDocIndex !== 0 && findActiveDocIndex !== -1) {
                        // Do the rearrange, and let that be the first document that is loaded
                        const removedValue = presentationDocs.splice(findActiveDocIndex, 1);
                        presentationDocs = [...removedValue, ...presentationDocs];
                        // Now , this is modified , it can now be used to sufficiently fetch the data needed
                    }
                }
                // Now Call the getDocDetails
                setRearrangedDoc(presentationDocs)
                // call it with the first index, and then you can handle the rest later
                setActiveDocumentId(presentationDocs[0].documentId)
                setPresenterActiveDocument(presentationDocs[0].documentId)
                setPresentationDetailsLoaded(true)

            }
        } catch (e) {
            console.log(e)

        } finally {

        }
    }

    const handleEventResponse = (id: string, eventName: string, status: any, message: any, presentaConnectionId: string,) => {
        if (eventName === "Attend") {
            console.log(message, status, "message result here")
        }
    }

    useEffect(() => {
        if (!overallDocLoadedDetails.loaded && !firstChunkLoading) {
            // Now , we need to load the next document
            // Now , i need to be able to call getDocumentId and with the next id
            const currentIndex = rearrangedDoc.findIndex(item => {
                return item.documentId === overallDocLoadedDetails.justLoadedDocId
            })
            // then the next details is to be obtained
            getDocumentDetails(rearrangedDoc[currentIndex + 1].documentId)
        }

    }, [overallDocLoadedDetails]);

    useEffect(() => {
        getPresentationDetails()
    }, []);

    useEffect(() => {
        if (presentationDetailsLoaded && activeDocumentId) {
            getDocumentDetails(activeDocumentId);
            setPresentationDetailsLoaded(false)
        }
    }, [presentationDetailsLoaded, activeDocumentId]);

    useEffect(() => {
        if (connected && connection?.state === "Connected") {
            connection.on("EventResponse", handleEventResponse)
            connection.on("MoveSlide", handleSlideMove)
            connection.invoke("Attend", friendlyId, 2, projectorConnectionId, false, friendlyId);
        }
        return () => {
            connection?.off("MoveSlide", handleSlideMove);
            connection?.off("EndPresentation");
            connection?.off("UserList");
        };
    }, [connected, connection]);

    return <>

        <UserPresentationWrapper>
            <div className="flex flex-grow min-h-[400px] w-full ">
                <div className="w-[80%] lg:w-[80%]">
                    <DesktopViewerPresentationScreen
                        outOfSync={userOutOfSyncState}
                        loading={firstChunkLoading}
                        imageSource={presentationDisplayDetails?.[activeDocumentId]?.[activeSlideIndex]?.imgUrl ?? ""}
                        isFullScreen={isFullScreen}
                        setIsFullScreen={setIsFullScreen}
                        idleState={idleState}
                        upcomingAgenda={upcomingEvent}
                        eventDetails={eventDetails}
                        eventPresentationsEnded={eventPresentationEnded}
                    />
                </div>
                <div className="pl-4 min-h-full overflow-hidden w-[20%]  lg:w-[20%] flex flex-col">
                    <div className="bg-[#F6F6F6] h-[30%] border rounded-lg p-2 flex justify-center items-center">
                        <QRCode value={`${window.location.origin}/join-presentation/${presentationId}`}
                                className="w-full h-full"/>

                    </div>
                    <div className="my-3">
                        <p className="text-[#ED6E33] text-center font-grotesk font-semibold">
                            <CopyToClipBoard text={`${window.location.origin}/join-presentation/${presentationId}`}>
                                <p className="min-w-full text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer">
                                    {`${window.location.origin}/join-presentation/${presentationId}`}
                                </p>
                            </CopyToClipBoard>

                        </p>
                    </div>
                    <div className="min-h-[300px] flex flex-grow  rounded-lg overflow-hidden ">
                        <img className="object-cover rounded-2xl h-full w-full"
                             src={advertiseImagePlaceholder} alt={""}/>
                    </div>
                    <div className="flex items-center gap-4 mt-3">
                        <span onClick={handlePrevious} className="bg-[#E2E2E2] px-6 py-3 rounded-full cursor-pointer">
                            <img src={arrowLeft} alt={""}/>
                        </span>
                        <p className="p-2 px-4 rounded-full bg-[#AFAFAF]">
                            {activeSlideIndex + 1}
                        </p>
                        <span onClick={handleNext} className="bg-[#E2E2E2] px-6 py-3 rounded-full cursor-pointer">
                            <img src={arrowRight} alt={""}/>
                        </span>

                    </div>
                </div>
            </div>
        </UserPresentationWrapper>

    </>
}